<template>
  <div>
    <div class="header d-flex align-items-center justify-content-end mb-2">
      <b-button
        variant="outline-primary"
        :to="{ name: 'admin-import-parents'}"
        :disabled="!$can('create', permissionSubjects.Parent)"
      >
        <span>Import Parents</span>
      </b-button>
    </div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100"
        >
          <v-select
            v-model="filterDataStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="false"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <div class="d-flex align-items-center">
            <b-button
              class="ml-1"
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
              <b-button
                class="ml-1"
                variant="primary"
                :disabled="isLoading"
                @click="exportParentsCSV"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                <span
                  class="ml-25 align-middle"
                >Export Parents</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchParentList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.avatar"
          />
          <b-link
            :to="{ name: 'admin-parent-profile', params: { id: data.item.id } }"
          >{{ data.item.full_name }}</b-link>
        </template>

        <!-- Column: country -->
        <template #cell(country)="data">
          {{ data.item.country }}
        </template>

        <!-- Column: children -->
        <template #cell(children)="data">
          {{ data.item.children_count }}
        </template>

        <!-- Column: balance -->
        <template #cell(balance)="data">
          <b-badge variant="light-secondary">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status !== 'Approved' && $can('update', permissionSubjects.LoginAsParent)"
              @click="approveAccount(data.item.id)"
            >
              <span>Approve Account</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('create', permissionSubjects.Payment)"
              v-b-modal.add-payment
            >
              <span>Make Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('read', permissionSubjects.LoginAsParent)"
              @click="loginAs(data.item.user_id, 'parent')"
            >
              <span>Log In as Parent</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Parent)"
              @click="showDeleteConfirmationModal(data.item)"
            >
              <span>Delete Parent</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

    <confirmation-modal
      v-if="deletableParent"
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="`Are you sure you want to permanently delete this user (${deletableParent.full_name})? ` +
      'All associated children and applications will also be removed.'"
      :is-loading="isUserDeleteLoading"
      @close-modal="confirmationModalHide = !confirmationModalHide"
      @confirm="deleteItem"
    />

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, VBModal,
  BDropdown, BDropdownItem, BAvatar, BBadge, BLink, BButton, BSpinner,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import vSelect from 'vue-select'
import { loginAs } from '@core/mixins/loginAs'

import store from '@/store'
import storeModule from '@/views/admin/parents/parentsStoreModule'
import useParentsList from '@/views/admin/parents/parents-list/useParentsList'
import useUsersList from '@/views/admin/users/users-list/useUsersList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

export default {
  components: {
    ConfirmationModal,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BButton,
    BSpinner,

    vSelect,

    TableSpinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [downloadExportFile, loginAs],
  data() {
    return {
      filterStatusOptions: [],
      payment: {
        method: 'Cash',
        amount: '',
        card: '',
        expDate: '',
        cvv: '',
      },
      schoolData: JSON.parse(localStorage.getItem('schoolData')),
      isLoading: false,
      confirmationModalHide: false,
      isUserDeleteLoading: false,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-parents'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchParentList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterDataStatus,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      actionOptions,

      loadScript,

      refetchData,

      resolveStatusVariant,
      createAndMountFormElements,
      setValidationError,

      updateParent,
      clearFilters,
    } = useParentsList(root)

    const { exportUsers } = useUsersList()

    return {
      fetchParentList,
      exportUsers,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      filterDataStatus,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      actionOptions,

      loadScript,

      refetchData,

      resolveStatusVariant,
      createAndMountFormElements,
      setValidationError,

      updateParent,
      clearFilters,
    }
  },
  computed: {
    ...mapGetters({
      programID: 'verticalMenu/getDefaultProgram',
      deletableParent: 'app-parents/getDeletableParent',
    }),
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  async created() {
    await this.$store.dispatch('app-parents/fetchParentStatuses')
      .then(response => {
        this.filterStatusOptions = response.data.data
      })
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    showDeleteConfirmationModal(parent) {
      this.$store.commit('app-parents/SET_DELETABLE_PARENT', parent)
      this.$nextTick(() => {
        this.$bvModal.show('confirmation')
      })
    },
    async deleteItem() {
      if (this.deletableParent.id) {
        try {
          this.isUserDeleteLoading = true
          await this.$store.dispatch('app-parents/deleteParent', { id: this.deletableParent.id })
            .then(() => {
              this.refetchData()
            })
          this.$store.commit('app-parents/SET_DELETABLE_PARENT', null)
        } finally {
          this.isUserDeleteLoading = false
        }
      }

      this.confirmationModalHide = !this.confirmationModalHide
    },
    async approveAccount(ID) {
      this.updateParent({
        id: ID,
        is_approved: true,
      }).then(() => {
        this.refetchData()
      })
    },
    async pushNewRout(id) {
      const token = await this.loginAsUser(id)
      window.location.href = `${this.schoolData.front_url}login-as-user?user=parent&userToken=${token}&url=${window.location.href}`
    },
    async exportParentsCSV() {
      const queryParams = {
        sortBy: 'name',
        sortDesc: 0,
        role: 'parent',
        programId: this.programID,
      }
      this.isLoading = true

      await this.exportUsers(queryParams).then(response => {
        if (response.status === 200) {
          this.downloadFile(response.data, 'Parents', 'csv')
          this.isLoading = false
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    updatePerPage(val) {
      localStorage.setItem('parentsPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('parentsPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterDataStatus = query.filterDataStatus || ''
    },
  },
}
</script>`

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
